<template>
  <!-- Main section start -->
  <section class="h-screen lg:h-screen main-bg" id="main">
      <div class="flex flex-col pt-44 md:pt-60 p-8 lg:pt-20 2xl:pt-44 lg:px-16 2xl:px-56">
          <div class="flex justify-center mt-16">
            <a href="https://roobet.com/?ref=mik" target="_blank">
             <img v-motion-pop-visible src="../assets/title-text.png" alt="title-text">
            </a>
          </div>
          <div class="absolute bottom-0 left-0 w-full">
            <img v-motion-pop-visible src="../assets/gold-line.png" class="w-full" alt="gold-line">
          </div>
      </div>
  </section>

  <section class="h-auto leaderboard-bg">
    <div class="flex flex-col lg:flex-row justify-center items-center pt-6 md:pt-8 lg:pt-24 gap-10 lg:gap-0">
       <img src="../assets/mik_banner.png" class="px-6 md:px-0" alt="mik_banner">
       <a href="https://roobet.com/?ref=mik" target="_blank">
        <img src="../assets/sign_up.png" class="px-6 md:px-0" alt="sign_up">
       </a>
    </div>
    <div class="flex justify-center items-center px-8 md:px-8 lg:pt-6" id="leaderboard">
      <div class="flex flex-col justify-center items-center mt-16 rounded-3xl">
        <div class="flex flex-row justify-center items-center">
          <img v-motion-pop-visible src="../assets/leaderboard.png" class="px-5" alt="leaderboard">
        </div>
      </div>
    </div>
    <div class="flex flex-col justify-center md:flex-row gap-3 mt-4 pt-10" style="font-family: Secular One, sans-serif;">
      <!-- <button @click="selectedLeaderboard = 1" v-motion-slide-visible-left :class="{'bg-[#998100] border-[#998100]': selectedLeaderboard == 1}" class="text-white bg-transparent hover:bg-[#998100] border border-white hover:border-[#998100] font-base py-4 px-[70px] relative uppercase rounded-xl">
          Packdraw
      </button> -->
      <button @click="selectedLeaderboard = 1" v-motion-slide-visible-right :class="{'bg-[#998100] border-[#998100]': selectedLeaderboard == 1}" class="text-white bg-transparent hover:bg-[#998100] border border-white hover:border-[#998100] font-base py-4 px-20 relative uppercase rounded-xl">
          Roobet
      </button>
    </div>
    <!-- <div v-if="selectedLeaderboard == 1">
        <PackdrawLeaderboardSection/>
    </div> -->
    <div v-if="selectedLeaderboard == 1">
      <RoobetLeaderboardSection/>
    </div>
    
    </section>

  <section class="h-auto shadow-inner social-bg" id="socials">
      <div class="flex flex-col justify-center pt-24 md:pt-24 lg:pt-24">
          <div class="flex justify-center px-4 md:px-6 lg:px-0">
              <img v-motion-pop-visible src="../assets/socials-title.png" alt="socials-title">
          </div>
          <div class="flex flex-wrap justify-center items-center mt-6 md:mt-6 lg:gap-24 lg:mt-8">
            <a href="https://www.youtube.com/@GambaGolds" target="_blank" rel="noopener noreferrer">
              <img v-motion-slide-visible-bottom src="../assets/youtube-card.png" alt="youtube">
            </a>
            <a href="https://discord.gg/gambagold" target="_blank" rel="noopener noreferrer">
              <img v-motion-slide-visible-bottom src="../assets/discord-card.png" alt="discord">
            </a>
          </div>
          <div class="flex flex-wrap justify-center items-center pb-14 lg:gap-24  md:mt-4 lg:mt-8 md:pb-24 lg:pb-14">
            <a href="https://twitter.com/MikhailisGG" target="_blank" rel="noopener noreferrer">
              <img v-motion-slide-visible-bottom src="../assets/twitter-card.png" alt="twitter">
            </a>
            <a href="https://kick.com/Mikhailis" target="_blank" rel="noopener noreferrer">
              <img v-motion-slide-visible-bottom src="../assets/kick-card.png" alt="click">
            </a>
          </div>
      </div>
  </section>
  
</template>

<script>
import { defineComponent } from 'vue';
// import PackdrawLeaderboardSection from '@/sections/PackdrawLeaderboardSection.vue';
import RoobetLeaderboardSection from '@/sections/RoobetLeaderboardSection.vue';

export default defineComponent({
  components: {
    RoobetLeaderboardSection,
    // PackdrawLeaderboardSection,
  },
  data() {
    return {
      loading: false,
      selectedLeaderboard: 1,
    };
  },
  methods: {
  },
});
</script>

<style scope>
body {
  background-color: #0B0B0C;
}
.main-bg {
  background-image: url('../assets/hero-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.social-bg {
  background-image: url('../assets/socials-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.leaderboard-bg {
  background-image: url('../assets/leaderboard-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
}
</style>
